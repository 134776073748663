<template>
  <validation-provider
    #default="validationContext"
    :name="label"
  >
    <b-form-group
      :label="label"
      :label-for="id"
      :class="classess"
    >
      <v-select
        :id="id"
        :value="value"
        :options="options"
        :clearable="clearable"
        :get-option-label="getOptionLabel"
        :reduce="reduce"
        :placeholder="placeholder"
        @search="fetchData"
        @input="(val) => {
          $emit('input', val)
          $emit('update:value', val)
        }"
        class="v-select-2"
      >
        <template v-slot:no-options="{ search, searching }">
          <template
            v-if="searching"
          >
            Поиск <em>{{ search }}</em>...
          </template>
          <em
            v-else
            style="opacity: 0.5;"
          >{{ searchLabel }}</em>
        </template>
      </v-select>
      <b-form-invalid-feedback :state="getValidationState(validationContext)">
        {{ validationContext.errors[0] }}
      </b-form-invalid-feedback>
    </b-form-group>
  </validation-provider>
</template>

<script>
import {
  BFormGroup,
  BFormInvalidFeedback,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ValidationProvider } from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import { onUnmounted } from '@vue/composition-api'
import searchSelectInputStoreModule from './searchSelectInputStoreModule'
import useSearchSelectInput from './useSearchSelectInput'
import store from '@/store'

export default {
  components: {
    BFormGroup,
    BFormInvalidFeedback,
    ValidationProvider,
    vSelect,
  },
  props: {
    opts: {
      type: Array,
      required: false,
      default: () => [],
    },
    value: {
      type: [Object, String, null],
      default: null,
    },
    apiId: {
      type: [Object, String, null],
      default: null,
    },
    api: {
      type: String,
      required: true,
    },
    initApi: {
      type: String,
      required: false,
      default: null,
    },
    id: {
      type: String,
      required: false,
    },
    name: {
      type: String,
      required: false,
    },
    label: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      required: false,
      default: () => '',
    },
    searchLabel: {
      type: String,
      required: false,
      default: () => '',
    },
    getOptionLabel: {
      type: Function,
      required: false,
    },
    reduce: {
      type: Function,
      required: false,
    },
    classess: {
      type: String,
      required: false,
      default: '',
    },
    clearable: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup(props, { emit }) {
    const STORE_MODULE_NAME = 'search-select-input'
    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, searchSelectInputStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    })

    const {
      getValidationState,
    } = formValidation(() => {})

    const {
      options,
      fetchData,
      fetchInitData,
    } = useSearchSelectInput(props, emit)

    if (props.initApi) {
      fetchInitData(-1)
    }

    return {
      options,
      getValidationState,
      fetchData,
    }
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.v-select-2 .vs__dropdown-menu {
  z-index: 2;
  position: absolute;
}
</style>
