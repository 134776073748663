<template>
  <span>
    <span v-if="!isReadMore">
      <span v-html="text.replace(/^(.{50}[^\.]*).*/, '$1')" /><span v-if="text.length > 50">...</span>
    </span>
    <span v-else>
      <span v-html="text" />
    </span>
    <b-link
      v-if="text.length > 50"
      class="d-block"
      @click.stop="toggleReadMore()"
    >&nbsp;
      <small v-if="!isReadMore">Читать целиком</small>
      <small v-else>Скрыть</small>
    </b-link>
  </span>
</template>
<script>
import { BLink } from 'bootstrap-vue'
import { ref } from '@vue/composition-api'

export default {
  name: 'ReadMore',
  components: { BLink },
  props: {
    text: {
      type: String,
      required: true,
    },
  },
  setup() {
    const isReadMore = ref(false)
    const toggleReadMore = () => {
      isReadMore.value = !isReadMore.value
    }

    return {
      isReadMore,
      toggleReadMore,
    }
  },
}
</script>
