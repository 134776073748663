import { ref } from '@vue/composition-api'
import { debounce } from 'lodash'
import store from '@/store'

export default function useSelectInput(props, emit) {
  const value = ref(null)
  const options = ref([])

  const fetchInitData = index => {
    store.dispatch('search-select-input/fetchData', {
      api: props.initApi,
    })
      .then(response => {
        const { data } = response.data
        options.value = [...props.opts, ...data]
        if (index > -1) {
          value.value = data[index]
          emit('update:value', data[index])
        }
      })
      .catch(() => {
        options.value = []
      })
  }

  const fetchData = debounce((val, index) => {
    if (val && val.length > 0) {
      store.dispatch('search-select-input/fetchData', {
        api: props.api,
        params: {
          q: val,
        },
      })
        .then(response => {
          const { data } = response.data
          options.value = data
          if (index > -1) {
            value.value = data[index]
            emit('update:value', data[index])
          }
        })
        .catch(() => {
          options.value = []
        })
    }
  }, 500)

  return {
    value,
    options,
    fetchData,
    fetchInitData,
  }
}
