<template>
  <!-- Checkout Options -->
  <div
    v-if="$store.state['app-ecommerce'].cartTotals"
    class="checkout-options"
  >
    <b-card>
      <div class="price-details">
        <h6 class="price-title mt-0">
          Подробная информация о цене
        </h6>
        <ul class="list-unstyled">
          <li
            v-for="(priceDetail, key) in $store.state['app-ecommerce'].cartTotals"
            :key="key"
          >
            <div
              v-if="priceDetail.type === 'total'"
            >
              <hr>
              <div class="price-detail">
                <div class="detail-title detail-total">
                  {{ priceDetail.title }}
                </div>
                <div class="detail-amt font-weight-bolder">
                  {{ priceDetail.amt }}
                </div>
              </div>
            </div>
            <div
              v-else
              class="price-detail"
            >
              <div class="detail-title">
                {{ priceDetail.title }}
              </div>
              <div :class="`detail-amt ${priceDetail.color}`">
                {{ priceDetail.amt }}
              </div>
            </div>
          </li>
        </ul>
        <b-button
          v-if="!end"
          variant="primary"
          block
          :disabled="loading || (label !== 'Оформить заказ' && (!selectedAddress || !selectedPVZ))"
          @click="$emit('next-step')"
        >
          <div class="d-flex align-items-center justify-content-center">
            <b-spinner
              v-if="loading"
              small
              class="mr-50"
            />
            <span>{{ label }}</span>
          </div>
        </b-button>
        <b-button
          v-else
          variant="primary"
          block
          @click="sendOrder()"
        >
          {{ label }}
        </b-button>
        <b-form-invalid-feedback :state="label === 'Оформить заказ' || !!selectedAddress">
          Не выбран адрес доставки
        </b-form-invalid-feedback>
        <b-form-invalid-feedback :state="label === 'Оформить заказ' || !!selectedPVZ">
          Не выбран пункт выдачи заказа
        </b-form-invalid-feedback>
      </div>

    </b-card>
  </div>
</template>

<script>
import {
  BButton, BCard,
  BFormInvalidFeedback,
  BSpinner,
  // BInputGroup, BFormInput, BInputGroupAppend,
} from 'bootstrap-vue'
import { ref, watch } from '@vue/composition-api'
import store from '@/store'

export default {
  components: {
    // BSV
    BButton,
    BCard,
    BFormInvalidFeedback,
    BSpinner,
    // BInputGroup,
    // BFormInput,
    // BInputGroupAppend,

    // SFC
  },
  props: {
    label: {
      type: String,
      default: () => 'Оформить заказ',
    },
    end: {
      type: Boolean,
      default: () => false,
    },
    loading: {
      type: Boolean,
      default: () => false,
    },
  },
  setup() {
    const sendOrder = () => {
      store.dispatch('app-ecommerce/sendOrder')
    }

    const selectedAddress = ref(true)
    const selectedPVZ = ref(true)

    watch(() => store.state['app-ecommerce'].cartAddress, () => {
      selectedAddress.value = true
      selectedPVZ.value = true
      // eslint-disable-next-line
      if (store.state['app-ecommerce'].orderData.deliveryType === 'address' || store.state['app-ecommerce'].orderData.deliveryType === 'post') {
        // eslint-disable-next-line
        if (
          !store.state['app-ecommerce'].cartAddress
          || !store.state['app-ecommerce'].cartAddress.address
          || !store.state['app-ecommerce'].cartAddress.address.length
          || !store.state['app-ecommerce'].cartAddress.address.filter(address => address.selected === true)[0]
        ) {
          selectedAddress.value = false
        }
      } else if (store.state['app-ecommerce'].orderData.deliveryType === 'office') {
        if (
          !store.state['app-ecommerce'].cartAddress
          || !store.state['app-ecommerce'].cartAddress.office
          || !store.state['app-ecommerce'].cartAddress.office.length
          || !store.state['app-ecommerce'].cartAddress.office.filter(office => office.selected === true)[0]
        ) {
          selectedAddress.value = false
        }
      } else {
        // eslint-disable-next-line
        if (
          !store.state['app-ecommerce'].cartAddress
          || !store.state['app-ecommerce'].cartAddress.pvz
          || !store.state['app-ecommerce'].cartAddress.pvz.length
          || !store.state['app-ecommerce'].cartAddress.pvz.filter(pvz => pvz.selected === true)[0]
        ) {
          selectedPVZ.value = false
        }
      }
    })

    return {
      sendOrder,
      selectedAddress,
      selectedPVZ,
    }
  },
}
</script>
