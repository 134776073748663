<template>

  <div class="checkout-items">
    <b-card
      v-for="product in products"
      :key="product.id"
      class="ecommerce-card"
      no-body
    >

      <!-- Product Image -->
      <div class="item-img">
        <b-link :to="{ name: 'shop-details', params: { id: product.id } }">
          <b-img
            :src="product.image ? server + product.image : server + '/views/products/0/no-image.png'"
            :alt="product.name"
          />
        </b-link>
      </div>

      <!-- Product Details: Card Body -->
      <b-card-body>
        <div class="item-name">
          <h6 class="mb-0">
            <b-link
              class="text-body"
              :to="{ name: 'shop-details', params: { id: product.id } }"
            >
              {{ product.name }}
            </b-link>
          </h6>
          <span class="item-company">{{ product.articul }}</span>
        </div>
        <span v-if="product.cont_DiscountStr !== 'Подарок'" :class="`${product.inStockColor} mb-1`">{{ product.inStock }}</span>
        <div class="item-quantity relative">
          <span class="quantity-title">Количество:</span>
          <b-form-spinbutton
            v-if="product.cont_DiscountStr !== 'Подарок'"
            v-model="product.qty"
            min="0"
            size="sm"
            class="ml-75"
            inline
          >
            <template #decrement>
              <feather-icon
                icon="MinusIcon"
              />
            </template>
            <template #increment>
              <feather-icon
                icon="PlusIcon"
              />
            </template>
          </b-form-spinbutton>
          <div
            v-if="product.cont_DiscountStr !== 'Подарок'"
            class="spinbutton-minus"
            @click="changeQtyItemInCart('minus', product)"
          ></div>
          <div
            v-if="product.cont_DiscountStr !== 'Подарок'"
            class="spinbutton-plus"
            @click="changeQtyItemInCart('plus', product)"
          ></div>
          <div v-if="product.cont_DiscountStr === 'Подарок'" class="ml-75">
            <span>{{ product.qty }}</span>
          </div>
        </div>
        <div
          v-if="product.testModeComp && product.testModeComp === 'enable'"
          class="testMode d-flex align-items-center mt-1"
        >
          <span>Взять на тест: </span>
          <b-form-checkbox
            :checked="product.testMode === '1' ? true : false"
            name="testMode"
            switch
            inline
            class="ml-75"
            @change="changeTestMode(product)"
          />
        </div>
        <span v-if="product.cont_DiscountStr !== 'Подарок'" :class="`delivery-date ${product.addtext1color}`">{{ product.addtext1 }}</span>
        <span v-if="product.cont_DiscountStr !== 'Подарок'" :class="`${product.addtext2color}`">{{ product.addtext2 }}</span>
      </b-card-body>

      <!-- Product Options/Actions -->
      <div class="item-options text-center">
        <div class="item-wrapper">
          <div v-if="product.cont_DiscountStr !== 'Подарок'" class="item-cost">
            <h4 class="item-price">
              {{ product.price * product.qty }} руб.
            </h4>
          </div>
          <p
            v-if="product.cont_DiscountStr"
            class="card-text shipping"
          >
            <b-badge
              pill
              variant="light-success"
            >
              {{ product.cont_DiscountStr }}
            </b-badge>
          </p>
        </div>
        <search-select-input
          :value.sync="product.partner"
          :opts="product.partner && [product.partner]"
          init-api="https://api.prohealthfactor.ru/api/v1/json/lkb/buyer/search/list/partner?q=--1"
          :get-option-label="option => option.query"
          :reduce="val => val.id"
          :clearable="true"
          label="Партнер"
          placeholder="Выбрать партнера"
          search-label="Номер, ФИО, телефон, email"
          api="https://api.prohealthfactor.ru/api/v1/json/lkb/buyer/search/list/partner"
          class="mt-1 text-left"
          @input="partnerInput(product, $event)"
        />
        <b-button
          v-if="product.cont_DiscountStr !== 'Подарок'"
          variant="light"
          class="remove-wishlist"
          @click="removeProductFromCartAll(product)"
        >
          <feather-icon
            icon="XIcon"
            class="mr-50"
          />
          <span>Удалить</span>
        </b-button>
      </div>
    </b-card>
  </div>

</template>

<script>
import {
  BCard, BCardBody, BLink, BImg, BButton,
  BFormSpinbutton, BBadge, BFormCheckbox,
} from 'bootstrap-vue'
import SearchSelectInput from '@core/components/input/search-select-input2/SearchSelectInput.vue'
import { ref } from '@vue/composition-api'
import { formatDate } from '@core/utils/filter'
import store from '@/store'
import { useEcommerce, useEcommerceUi } from '../useEcommerce'

export default {
  components: {
    BCard,
    BCardBody,
    BLink,
    BImg,
    BButton,
    BFormSpinbutton,
    BBadge,
    BFormCheckbox,
    SearchSelectInput,
  },
  methods: {
    changeTestMode(product) {
      if (product.testMode === '0') {
        this.$store.dispatch('app-ecommerce/addProductTestMode', product.id).then(() => {
          // eslint-disable-next-line
          product.testMode = '1'
        })
      } else if (product.testMode === '1') {
        this.$store.dispatch('app-ecommerce/removeProductTestMode', product.id).then(() => {
          // eslint-disable-next-line
          product.testMode = '0'
        })
      }
    },
  },
  setup() {
    const products = ref([])

    const fetchCartPriceDetail = () => {
      store.dispatch('app-ecommerce/fetchCartPriceDetail')
        .then(response => {
          const { data } = response.data
          store.commit('app-ecommerce/UPDATE_CART_TOTALS', data || [])
        })
    }

    const { toggleProductInWishlist } = useEcommerceUi()
    const { removeProductFromCart } = useEcommerce()
    const removeProductFromCartClick = product => {
      removeProductFromCart(product.id)
        .then(() => {
          const productIndex = products.value.findIndex(p => p.id === product.id)
          products.value.splice(productIndex, 1)

          if (products.value && products.value.length) {
            store.commit('app-ecommerce/UPDATE_CART_ITEMS_COUNT', products.value.reduce((a, o) => parseInt(a, 10) + parseInt(o.qty, 10), 0))
          }
          fetchCartPriceDetail()
        })
    }

    const fetchCartAndPriceDetail = () => {
      store.dispatch('app-ecommerce/fetchCartAndPriceDetail')
        .then(response => {
          const { data } = response.data
          if (data) {
            products.value = data[0].content
            // store.commit('app-ecommerce/UPDATE_CART_TOTALS', data[0].priceDetail || [])
            fetchCartPriceDetail()
          }
        })
    }

    const removeProductFromCart2 = product => {
      store.dispatch('app-ecommerce/removeProductFromCart', product.id)
        .then(() => {
          if (parseInt(product.qty, 10) === 0) {
            const itemIndex = products.value.findIndex(p => p.id === product.id)
            products.value.splice(itemIndex, 1)
          }

          // Update count in cart items state
          if (products.value && products.value.length) {
            store.commit('app-ecommerce/UPDATE_CART_ITEMS_COUNT', products.value.reduce((a, o) => parseInt(a, 10) + parseInt(o.qty, 10), 0))
          } else {
            store.commit('app-ecommerce/UPDATE_CART_ITEMS_COUNT', 0)
          }
          fetchCartAndPriceDetail()
        })
    }

    const removeProductFromCartAll = product => {
      store.dispatch('app-ecommerce/removeProductFromCartAll', product.id)
        .then(() => {
          const itemIndex = products.value.findIndex(p => p.id === product.id)
          products.value.splice(itemIndex, 1)
          // Update count in cart items state
          if (products.value && products.value.length) {
            store.commit('app-ecommerce/UPDATE_CART_ITEMS_COUNT', products.value.reduce((a, o) => parseInt(a, 10) + parseInt(o.qty, 10), 0))
          }
          fetchCartAndPriceDetail()
        })
    }

    const addProductInCart = product => {
      store.dispatch('app-ecommerce/addProductInCart', product.id).then(() => {
        if (products.value && products.value.length) {
          store.commit('app-ecommerce/UPDATE_CART_ITEMS_COUNT', products.value.reduce((a, o) => parseInt(a, 10) + parseInt(o.qty, 10), 0))
        }
        fetchCartAndPriceDetail()
      })
    }

    const changeQtyItemInCart = (val, item) => {
      if (val === 'minus') {
        removeProductFromCart2(item)
        // eslint-disable-next-line
        item.qty = item.qty - 1
      }
      if (val === 'plus') {
        addProductInCart(item)
        // eslint-disable-next-line
        item.qty = item.qty + 1
      }
    }

    const server = process.env.VUE_APP_SERVER

    fetchCartAndPriceDetail()

    const partnerInput = (product, id) => {
      if (id) {
        store.dispatch('app-ecommerce/addProductPartner', {
          productId: product.id,
          xparam1: id,
        }).then(() => {
          fetchCartAndPriceDetail()
        })
      } else {
        store.dispatch('app-ecommerce/removeProductPartner', { productId: product.id }).then(() => {
          fetchCartAndPriceDetail()
        })
      }
    }

    return {
      server,
      products,
      removeProductFromCartAll,

      // UI
      toggleProductInWishlist,
      removeProductFromCartClick,
      changeQtyItemInCart,

      partnerInput,

      // Filter
      formatDate,
    }
  },
}
</script>
<style scoped>
  .relative {
    position: relative;
  }

  .spinbutton-minus {
    position: absolute;
    left: 100px;
    top: 0;
    width: 30px;
    height: 100%;
    cursor: pointer;
  }
  .spinbutton-plus {
    position: absolute;
    top: 0;
    width: 30px;
    height: 100%;
    left: 165px;
    height: 100%;
    cursor: pointer;
  }
</style>
