<template>
  <form-wizard
    ref="refFormWizard"
    color="#1D71B8"
    :title="null"
    :subtitle="null"
    finish-button-text="Submit"
    back-button-text="Previous"
    hide-buttons
    class="checkout-form-wizard steps-transparent"
    @on-change="fetchCartPay"
  >
    <!-- account detail tab -->
    <tab-content
      title="Корзина"
      icon="feather icon-shopping-cart"
    >
      <e-commerce-checkout-step-cart :next-step="formWizardNextStep" />
    </tab-content>

    <!-- address -->
    <tab-content
      title="Доставка"
      icon="feather icon-home"
    >
      <e-commerce-checkout-step-address
        :next-step="formWizardNextStep"
      />
    </tab-content>

    <!-- social link -->
    <tab-content
      title="Оплата"
      icon="feather icon-credit-card"
    >
      <e-commerce-checkout-step-payment />
    </tab-content>
  </form-wizard>
</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import { ref } from '@vue/composition-api'
import ECommerceCheckoutStepPayment from './ECommerceCheckoutStepPayment.vue'
import ECommerceCheckoutStepCart from './ECommerceCheckoutStepCart.vue'
import ECommerceCheckoutStepAddress from './ECommerceCheckoutStepAddress.vue'
import store from '@/store'

export default {
  components: {
    // 3rd Party
    FormWizard,
    TabContent,

    // SFC
    ECommerceCheckoutStepCart,
    ECommerceCheckoutStepAddress,
    ECommerceCheckoutStepPayment,
  },
  mounted() {
    this.$nextTick(() => {
      const navCartDropdownItem = document.getElementsByClassName('navCartDropdownItem')
      const cartDropdownMenu = document.getElementsByClassName('cartDropdownMenu')

      if (navCartDropdownItem.length && cartDropdownMenu.length) {
        navCartDropdownItem[0].classList.remove('show')
        cartDropdownMenu[0].classList.remove('show')
      }
    })
  },
  setup() {
    const refFormWizard = ref(null)
    const fetchCartPay = val => {
      if (val === 1) {
        store.dispatch('app-ecommerce/fetchCartPay')
          .then(response => {
            const { data } = response.data
            if (data) {
              store.commit('app-ecommerce/UPDATE_CART_PAYMENT_TOTALS', data[0].priceDetail)
            }
          })
      }

      if (val === 0) {
        store.dispatch('app-ecommerce/fetchCartAddress', {
          xparam1: store.state['app-ecommerce'].orderData.deliveryType || 'PVZ',
        })
          .then(response => {
            const { data } = response.data
            if (data) {
              store.commit('app-ecommerce/UPDATE_CART_ADDRESS', { ...data[0] })

              if (store.state['app-ecommerce'].cartAddress.address && store.state['app-ecommerce'].cartAddress.address.length) {
                store.dispatch('app-ecommerce/addCartAddress', {
                  xparam1: JSON.stringify({
                    ...store.state['app-ecommerce'].cartAddress,
                    deliveryType: 'address',
                  }),
                })
                  .then(() => {
                    store.dispatch('app-ecommerce/fetchCartPriceDetail')
                      .then(res => {
                        const { data: d } = res.data
                        store.commit('app-ecommerce/UPDATE_CART_TOTALS', d || [])
                      })
                  })
              }
            }
          })
      }
    }

    const formWizardNextStep = () => {
      refFormWizard.value.nextTab()
    }

    return {
      refFormWizard,
      formWizardNextStep,
      fetchCartPay,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '~@core/scss/base/pages/app-ecommerce.scss';
@import '~vue-form-wizard/dist/vue-form-wizard.min.css';
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss" scoped>
.checkout-form-wizard ::v-deep {
  .wizard-tab-content {
    box-shadow: none !important;
    background: transparent !important;
    padding: 0;
  }
}
</style>
