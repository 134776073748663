<template>
  <!-- Checkout Options -->
  <div
    v-if="$store.state['app-ecommerce'].cartPaymentTotals"
    class="checkout-options"
  >
    <b-card>
      <div class="price-details">
        <h6 class="price-title mt-0">
          Подробная информация о цене
        </h6>
        <ul class="list-unstyled">
          <li
            v-for="(priceDetail, key) in $store.state['app-ecommerce'].cartPaymentTotals"
            :key="key"
          >
            <div
              v-if="priceDetail.type === 'total'"
            >
              <hr>
              <div class="price-detail">
                <div class="detail-title detail-total">
                  {{ priceDetail.title }}
                </div>
                <div class="detail-amt font-weight-bolder">
                  {{ priceDetail.amt }}
                </div>
              </div>
            </div>
            <div
              v-else-if="priceDetail.type === 'number'"
            >
              <div class="price-detail mb-50">
                <div class="detail-title">
                  {{ priceDetail.title }}
                </div>
                <div :class="`detail-amt ${priceDetail.color}`">
                  -{{ $store.state['app-ecommerce'].paumentData.acc.balanceForPay || 0 }}.00 руб.
                </div>
              </div>
              <b-form-input
                id="accSum"
                v-model="$store.state['app-ecommerce'].paumentData.acc.balanceForPay"
                type="number"
                size="sm"
                class="mb-50"
                @input="changeAccSum({ value: $store.state['app-ecommerce'].paumentData.acc.balanceForPay })"
              />
            </div>
            <div
              v-else
              class="price-detail"
            >
              <div class="detail-title">
                {{ priceDetail.title }}
              </div>
              <div :class="`detail-amt ${priceDetail.color}`">
                {{ priceDetail.amt }}
              </div>
            </div>
          </li>
        </ul>
        <b-button
          v-if="!end"
          variant="primary"
          block
          @click="$emit('next-step')"
        >
          {{ label }}
        </b-button>
        <b-button
          v-else
          id="processOrderBtn"
          variant="primary"
          block
          :disabled="loading"
          @click="processOrder()"
        >
          <div class="d-flex align-items-center justify-content-center">
            <b-spinner
              v-if="loading"
              small
              class="mr-50"
            />
            <span>{{ $store.state['app-ecommerce'].cartPaymentTotals[$store.state['app-ecommerce'].cartPaymentTotals.length - 1].title }}</span>
          </div>
        </b-button>
      </div>
      <form
        v-if="payment && payment.value === 3"
        ref="paymentForm"
        method="POST"
        :action="payment.paymentPageUrl[0].action"
        style="display:block;height:1px;width:1px;overflow:hidden;"
      >
        <input
          type="hidden"
          name="sum"
          :value="payment.paymentPageUrl[0].params[0].sum"
        >
        <input
          type="hidden"
          name="orderid"
          :value="payment.paymentPageUrl[0].params[0].orderid"
        >
        <input
          type="hidden"
          name="service_name"
          :value="payment.paymentPageUrl[0].params[0].service_name"
        >
        <input
          type="hidden"
          name="user_result_callback"
          :value="payment.paymentPageUrl[0].params[0].user_result_callback"
        >
        <input
          type="hidden"
          name="cart"
          :value="payment.paymentPageUrl[0].params[0].cart && JSON.stringify(payment.paymentPageUrl[0].params[0].cart)"
        >
        <input
          type="hidden"
          name="clientid"
          :value="payment.paymentPageUrl[0].params[0].clientid"
        >
        <input
          type="hidden"
          name="client_email"
          :value="payment.paymentPageUrl[0].params[0].client_email"
        >
      </form>
    </b-card>
  </div>
</template>

<script>
import {
  BButton, BCard,
  // BFormGroup,
  BFormInput,
  BSpinner,
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import { debounce } from 'lodash'
import store from '@/store'

export default {
  components: {
    // BSV
    BButton,
    BCard,
    // BFormGroup,
    BFormInput,
    BSpinner,

    // SFC
  },
  props: {
    label: {
      type: String,
      default: () => 'Оформить заказ',
    },
    end: {
      type: Boolean,
      default: () => false,
    },
  },
  setup(ctx, { root }) {
    const loading = ref(false)
    const payment = ref(null)
    const paymentForm = ref(null)

    const sendOrder = paymentPageUrl => {
      loading.value = true
      store.dispatch('app-ecommerce/sendOrder')
        .then(response => {
          const { data } = response.data
          loading.value = false
          root.$swal({
            icon: 'success',
            title: data.extras.title,
            text: data.extras.text,
            customClass: {
              confirmButton: 'btn btn-success',
            },
          }).then(() => {
            loading.value = false
            store.commit('app-ecommerce/UPDATE_CART_ITEMS_COUNT', 0)
            window.location.replace(paymentPageUrl.action)
            // window.open(paymentPageUrl.action, '_blank')
          })
        }).catch(() => {
          loading.value = false
        })
    }

    const sendPaykeeperOrder = () => {
      loading.value = true
      store.dispatch('app-ecommerce/sendOrder')
        .then(() => {
          loading.value = false
          store.commit('app-ecommerce/UPDATE_CART_ITEMS_COUNT', 0)
          root.$nextTick(() => {
            paymentForm.value.submit()
          })
        }).catch(() => {
          loading.value = false
        })
    }

    const processOrder = () => {
      if (store.state['app-ecommerce'].cartPayments) {
        // eslint-disable-next-line
        payment.value = store.state['app-ecommerce'].cartPayments.filter(
          // eslint-disable-next-line
          item => item.value === store.state['app-ecommerce'].orderData.pay.value
        )[0]

        if (payment.value) {
          if (payment.value.value === 3) {
            sendPaykeeperOrder()
          } else {
            sendOrder(payment.value.paymentPageUrl[0])
          }
        }
      }
    }

    const changeAccSum = debounce(val => {
      if (!val.value) {
        // eslint-disable-next-line
        val.value = 0
      }
      const accSum = parseInt(val.value, 10)

      if (accSum > store.state['app-ecommerce'].paumentData.acc.balanceForPay) {
        // eslint-disable-next-line
        val.value = store.state['app-ecommerce'].paumentData.acc.balanceForPay
      }

      store.commit('app-ecommerce/UPDATE_ORDER_DATA', {
        pay: {
          value: store.state['app-ecommerce'].orderData.pay.value,
          accSum,
        },
      })

      store.dispatch('app-ecommerce/addCartPay', {
        xparam1: JSON.stringify({
          value: store.state['app-ecommerce'].orderData.pay.value,
          accSum,
        }),
      })
        .then(() => {
          store.dispatch('app-ecommerce/fetchCartPay')
            .then(response => {
              const { data } = response.data
              if (data) {
                store.commit('app-ecommerce/UPDATE_CART_PAYMENT_TOTALS', data[0].priceDetail)
              }
            })
        })
    }, 1000)

    return {
      loading,
      changeAccSum,
      sendOrder,
      sendPaykeeperOrder,
      processOrder,
      payment,
      paymentForm,
    }
  },
}
</script>
