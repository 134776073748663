<template>
  <validation-observer
    #default="{ handleSubmit }"
    ref="refFormObserver"
  >
    <b-form
      class="list-view product-checkout"
      @submit.prevent="handleSubmit(onSubmit)"
    >

      <!-- Left Form -->
      <b-card
        v-if="!$store.state['app-ecommerce'].cartAddress"
        no-body
        class="relative"
      >
        <b-overlay
          :show="true"
          no-wrap
          :variant="$store.state.appConfig.layout.skin === 'dark' ? 'black' : 'white'"
        />
      </b-card>
      <b-card
        v-if="$store.state['app-ecommerce'].cartAddress"
        no-body
      >
        <b-card-header class="flex-column align-items-start">
          <b-card-title>Покупатель:</b-card-title>
          <b-card-text class="text-muted mt-25" />
        </b-card-header>
        <b-card-body>
          <b-row>
            <b-col
              cols="12"
              md="4"
            >
              <validation-provider
                #default="validationContext"
                name="LN"
                rules="required"
              >
                <b-form-group
                  label="Фамилия"
                  label-for="LN"
                  class="mb-2"
                >
                  <b-form-input
                    id="LN"
                    v-model="$store.state['app-ecommerce'].cartAddress.LN"
                    :state="getValidationState(validationContext)"
                    trim
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
              cols="12"
              md="4"
            >
              <validation-provider
                #default="validationContext"
                name="FN"
                rules="required"
              >
                <b-form-group
                  label="Имя"
                  label-for="FN"
                  class="mb-2"
                >
                  <b-form-input
                    id="FN"
                    v-model="$store.state['app-ecommerce'].cartAddress.FN"
                    :state="getValidationState(validationContext)"
                    trim
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                label="Отчество"
                label-for="MN"
                class="mb-2"
              >
                <b-form-input
                  id="MN"
                  v-model="$store.state['app-ecommerce'].cartAddress.MN"
                  trim
                />
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
              md="6"
            >
              <validation-provider
                #default="validationContext"
                name="email"
                rules="required"
              >
                <b-form-group
                  label="E-mail"
                  label-for="email"
                  class="mb-2"
                >
                  <b-form-input
                    id="email"
                    v-model="$store.state['app-ecommerce'].cartAddress.email"
                    :state="getValidationState(validationContext)"
                    trim
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
              cols="12"
              md="6"
            >
              <validation-provider
                #default="validationContext"
                name="tel"
              >
                <b-form-group
                  label="Телефон"
                  label-for="tel"
                  class="mb-2"
                >
                  <b-form-input
                    id="tel"
                    v-model="$store.state['app-ecommerce'].cartAddress.tel"
                    v-mask="'+7(###) ###-####'"
                    :state="getValidationState(validationContext)"
                    placeholder="+7(999) 999-9999"
                    trim
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
              cols="12"
              md="12"
            >
              <validation-provider
                name="info"
              >
                <b-form-group
                  label="Комментарий"
                  label-for="info"
                  class="mb-2"
                >
                  <b-form-textarea
                    id="info"
                    v-model="$store.state['app-ecommerce'].cartAddress.info"
                    trim
                  />
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col sm="12">
              <hr>
            </b-col>
          </b-row>
          <b-tabs
            pills
            class="mt-1"
          >
            <b-tab
              :active="$store.state['app-ecommerce'].orderData.deliveryType === 'PVZ'"
              class="relative"
              @click="fetchCartAddress('PVZ')"
            >
              <template #title>
                <font-awesome-icon icon="fas fa-map-location-dot" />
                <span>Доставка в пункт выдачи заказа</span>
              </template>
              <b-overlay
                :show="loadingPVZ"
                no-wrap
                :variant="$store.state.appConfig.layout.skin === 'dark' ? 'black' : 'white'"
              />
              <b-row>
                <b-col
                  sm="12"
                  class="mt-1"
                >
                  <b-card-title>Пункты выдачи:</b-card-title>
                </b-col>

                <b-col
                  sm="12"
                >
                  <b-row
                    class="match-height"
                  >
                    <b-col
                      v-for="(pvz, key) in $store.state['app-ecommerce'].cartAddress.pvz"
                      :key="key"
                      cols="12"
                      md="6"
                    >
                      <b-card
                        :border-variant="$store.state['app-ecommerce'].cartAddress.pvz[key].selected ? 'primary' : ''"
                        class="cursor-pointer"
                        no-body
                        @click="selectPVZ(key)"
                      >
                        <b-card-header class="p-0">
                          <b-card-title />
                          <b-dropdown
                            variant="transparent"
                            right
                            no-caret
                            size="sm"
                          >
                            <template #button-content>
                              <feather-icon
                                icon="MoreVerticalIcon"
                                size="18"
                                class="cursor-pointer"
                              />
                            </template>
                            <b-dropdown-item
                              @click.stop="deletePVZ(key)"
                            >
                              <span>Удалить</span>
                            </b-dropdown-item>
                          </b-dropdown>
                        </b-card-header>
                        <b-card-body>
                          <b-table
                            :items="[pvz]"
                            :fields="fields2"
                            stacked
                            class="address-table"
                          >
                            <template #cell(price)="data">
                              <div>{{ data.item.price }} руб.</div>
                            </template>
                            <template #cell(description)="data">
                              <read-more
                                v-if="data.item.description"
                                :text="data.item.description"
                              />
                            </template>
                          </b-table>
                        </b-card-body>
                      </b-card>
                    </b-col>
                    <b-col
                      cols="12"
                      md="6"
                    >
                      <b-card
                        body-class="d-flex justify-content-center align-items-center"
                      >
                        <b-button
                          variant="outline-primary"
                          @click="showAddPVZ"
                        >
                          Добавить пункт выдачи
                        </b-button>
                      </b-card>
                    </b-col>
                  </b-row>
                </b-col>

                <b-modal
                  v-model="modalMapShow"
                  title="Пункты выдачи"
                  size="lg"
                  @ok="addPVZ"
                  @hide="selectedMarker = null"
                >
                  <b-overlay
                    :show="loadingPVZMap"
                    no-wrap
                    :variant="$store.state.appConfig.layout.skin === 'dark' ? 'black' : 'white'"
                  />
                  <b-form-group
                    label="Город/Регион"
                    label-for="town_map_select"
                    class="mb-1"
                  >
                    <v-select
                      id="town_map_select"
                      :clearable="false"
                      :get-option-label="option => option.query"
                      :options="options2"
                      @search="fetchTownCoord"
                      @input="searchInput"
                    >
                      <template v-slot:no-options="{ search, searching }">
                        <template
                          v-if="searching"
                        >
                          Поиск <em>{{ search }}</em>...
                        </template>
                        <em
                          v-else
                        >Поиск...</em>
                      </template>
                    </v-select>
                  </b-form-group>
                  <b-form-group
                    v-if="$store.state['app-ecommerce'].cartAddress.delivery"
                    label="Служба доставки"
                    label-for="delivery_select"
                    class="mb-2"
                  >
                    <b-form-radio-group
                      id="Служба доставки"
                      v-model="selectedDelivery"
                      text-field="name"
                      @input="deliveryInput"
                    >
                      <b-form-radio
                        v-for="delivery in $store.state['app-ecommerce'].cartAddress.delivery"
                        :key="delivery.value"
                        :class="delivery.color.replace('#', '_')"
                        :value="delivery.value"
                      >
                        {{ delivery.name }}
                      </b-form-radio>
                    </b-form-radio-group>
                  </b-form-group>
                  <div
                    ref="mapContainer"
                    class="map-container"
                  >
                    <yandex-map
                      v-if="markers"
                      :coords="coords || $store.state['app-ecommerce'].cartAddress.map"
                      :zoom="(tick && tick.zoom) || scale || 10"
                      :cluster-options="clusterOptions"
                      :cluster-callbacks="clickOnCluster"
                      @map-was-initialized="mapLoaded"
                      @click="handleClickMap"
                    >
                      <ymap-marker
                        v-for="(marker, i) in markers"
                        :key="i"
                        :coords="marker.coords"
                        :marker-id="i"
                        hint-content=""
                        :balloon="{
                          header: `${marker.name} ${marker.deliveryName}`,
                          body: marker.address,
                          footer: marker.description,
                        }"
                        :options="{
                          preset: marker.colorIcon,
                        }"
                        cluster-name="clusterName"
                        @click="handleMarkerClick(marker, $event)"
                        @balloonopen="handleBalloonOpen(marker, $event)"
                        @balloonclose="handleBalloonClose"
                      />
                    </yandex-map>
                  </div>
                  <template #modal-footer="{ ok, cancel }">
                    <strong v-if="selectedMarker">Выбрано: {{ selectedMarker.name }} {{ selectedMarker.deliveryName }}</strong>
                    <b-button
                      variant="flat-danger"
                      @click="() => {
                        selectedMarker = null
                        cancel()
                      }"
                    >
                      Отмена
                    </b-button>
                    <b-button
                      variant="primary"
                      @click="ok()"
                    >
                      Выбрать
                    </b-button>
                  </template>
                </b-modal>

                <!-- Submit Button -->
                <!-- <b-col cols="12">
                  <b-button
                    type="submit"
                    variant="primary"
                    :disabled="loading"
                  >
                    <div class="d-flex align-items-center justify-content-center">
                      <b-spinner
                        v-if="loading"
                        small
                        class="mr-50"
                      />
                      <span>Сохранить</span>
                    </div>
                  </b-button>
                </b-col> -->
              </b-row>
            </b-tab>
            <!-- <b-tab
              :active="$store.state['app-ecommerce'].orderData.deliveryType === 'address'"
              class="relative"
              @click="fetchCartAddress('address')"
            >
              <template #title>
                <font-awesome-icon icon="fas fa-truck" />
                <span>Курьерская доставка</span>
              </template>
              <b-overlay
                :show="loadingAddress"
                no-wrap
                :variant="$store.state.appConfig.layout.skin === 'dark' ? 'black' : 'white'"
              />
              <b-row>
                <b-col
                  sm="12"
                  class="mt-1"
                >
                  <b-card-title>Адрес:</b-card-title>
                </b-col>

                <b-col
                  sm="12"
                >
                  <b-row
                    class="match-height"
                  >
                    <b-col
                      v-for="(address, key) in $store.state['app-ecommerce'].cartAddress.address"
                      :key="key"
                      cols="12"
                      md="6"
                    >
                      <b-card
                        :border-variant="$store.state['app-ecommerce'].cartAddress.address[key].selected ? 'primary' : ''"
                        class="cursor-pointer"
                        no-body
                        @click="selectAddress(key)"
                      >
                        <b-card-header class="p-0">
                          <b-card-title />
                          <b-dropdown
                            variant="transparent"
                            right
                            no-caret
                            size="sm"
                          >
                            <template #button-content>
                              <feather-icon
                                icon="MoreVerticalIcon"
                                size="18"
                                class="cursor-pointer"
                              />
                            </template>
                            <b-dropdown-item
                              @click.stop="changeAddress(key)"
                            >
                              <span>Изменить</span>
                            </b-dropdown-item>
                            <b-dropdown-item
                              @click.stop="deleteAddress(key)"
                            >
                              <span>Удалить</span>
                            </b-dropdown-item>
                          </b-dropdown>
                        </b-card-header>
                        <b-card-body>
                          <b-table
                            :items="[address]"
                            :fields="fields"
                            stacked
                            class="address-table"
                          >
                            <template #cell(country)="data">
                              <b-form-input
                                v-if="$store.state['app-ecommerce'].cartAddress.address[key].isEdit"
                                v-model="$store.state['app-ecommerce'].cartAddress.address[key].country"
                                type="text"
                                @click.stop="() => {}"
                              />
                              <span v-else>{{ data.value }}</span>
                            </template>
                            <template #cell(flatt)="data">
                              <b-form-input
                                v-if="$store.state['app-ecommerce'].cartAddress.address[key].isEdit"
                                v-model="$store.state['app-ecommerce'].cartAddress.address[key].flatt"
                                type="text"
                                @click.stop="() => {}"
                              />
                              <span v-else>{{ data.value }}</span>
                            </template>
                            <template #cell(house)="data">
                              <b-form-input
                                v-if="$store.state['app-ecommerce'].cartAddress.address[key].isEdit"
                                v-model="$store.state['app-ecommerce'].cartAddress.address[key].house"
                                type="text"
                                @click.stop="() => {}"
                              />
                              <span v-else>{{ data.value }}</span>
                            </template>
                            <template #cell(postcode)="data">
                              <b-form-input
                                v-if="$store.state['app-ecommerce'].cartAddress.address[key].isEdit"
                                v-model="$store.state['app-ecommerce'].cartAddress.address[key].postcode"
                                type="text"
                                @click.stop="() => {}"
                              />
                              <span v-else>{{ data.value }}</span>
                            </template>
                            <template #cell(street)="data">
                              <b-form-input
                                v-if="$store.state['app-ecommerce'].cartAddress.address[key].isEdit"
                                v-model="$store.state['app-ecommerce'].cartAddress.address[key].street"
                                type="text"
                                @click.stop="() => {}"
                              />
                              <span v-else>{{ data.value }}</span>
                            </template>
                            <template #cell(town_select)="data">
                              <b-form-group
                                v-if="$store.state['app-ecommerce'].cartAddress.address[key].isEdit"
                                label="Город/Регион"
                                label-for="town_select"
                                class="mb-2"
                              >
                                <v-select
                                  id="town_select"
                                  v-model="$store.state['app-ecommerce'].cartAddress.address[key].town_select"
                                  :clearable="false"
                                  :get-option-label="option => option.query"
                                  :options="options.length ? options : (($store.state['app-ecommerce'].cartAddress.address[key].town_select && [$store.state['app-ecommerce'].cartAddress.address[key].town_select]) || [])"
                                  @search="fetchData"
                                  @click.native.stop="() => {}"
                                >
                                  <template v-slot:no-options="{ search, searching }">
                                    <template
                                      v-if="searching"
                                    >
                                      Поиск <em>{{ search }}</em>...
                                    </template>
                                    <em
                                      v-else
                                    >Поиск...</em>
                                  </template>
                                </v-select>
                              </b-form-group>
                              <span v-else>{{ data.value.query }}</span>
                            </template>
                          </b-table>
                          <div
                            v-if="$store.state['app-ecommerce'].cartAddress.address[key].isEdit"
                          >
                            <b-button
                              variant="primary"
                              class="mt-1"
                              :disabled="$store.state['app-ecommerce'].cartAddress.address[key].loading"
                              @click.stop="saveAddress(key)"
                            >
                              <b-spinner
                                v-if="$store.state['app-ecommerce'].cartAddress.address[key].loading"
                                small
                                class="mr-50"
                              />
                              <span>Сохранить</span>
                            </b-button>
                            <b-button
                              variant="outline-danger"
                              class="mt-1 ml-75"
                              @click.stop="cancelAddress(key)"
                            >
                              <span>Отменить</span>
                            </b-button>
                          </div>
                        </b-card-body>
                      </b-card>
                    </b-col>
                    <b-col
                      cols="12"
                      md="6"
                    >
                      <b-card
                        body-class="d-flex justify-content-center align-items-center"
                      >
                        <b-button
                          variant="outline-primary"
                          @click="addAddress"
                        >
                          Добавить адрес
                        </b-button>
                      </b-card>
                    </b-col>
                  </b-row>
                </b-col>

                <b-col sm="12">
                  <hr>
                </b-col>

                <b-col
                  sm="12"
                  class="mt-1"
                >
                  <b-card-title>Доставка:</b-card-title>
                </b-col>

                <b-col
                  sm="12"
                >
                  <b-row class="match-height">
                    <b-col
                      v-for="(delivery, key) in $store.state['app-ecommerce'].cartAddress.delivery"
                      :key="key"
                      md="4"
                    >
                      <b-card
                        :img-src="server + delivery.image"
                        img-top
                        :img-alt="delivery.name"
                        :border-variant="delivery.default === true ? 'primary' : ''"
                        class="cursor-pointer"
                        @click="selectDelivery(delivery)"
                      >
                        <b-card-text>
                          <h5>{{ delivery.name }}</h5>
                        </b-card-text>
                        <b-card-text v-if="delivery.description">
                          <div v-html="delivery.description" />
                        </b-card-text>
                        <b-card-text>
                          <strong v-if="delivery.priceText"><span v-html="delivery.priceText" /></strong>
                        </b-card-text>
                      </b-card>
                    </b-col>
                  </b-row>
                </b-col>

                <b-col cols="12">
                  <b-button
                    type="submit"
                    variant="primary"
                    :disabled="loading"
                  >
                    <div class="d-flex align-items-center justify-content-center">
                      <b-spinner
                        v-if="loading"
                        small
                        class="mr-50"
                      />
                      <span>Сохранить</span>
                    </div>
                  </b-button>
                </b-col>
              </b-row>
            </b-tab> -->
            <b-tab
              :active="$store.state['app-ecommerce'].orderData.deliveryType === 'post'"
              class="relative"
              @click="fetchCartAddress('post')"
            >
              <template #title>
                <font-awesome-icon icon="fas fa-mail-bulk" />
                <span>Почта России</span>
              </template>
              <b-overlay
                :show="loadingAddress"
                no-wrap
                :variant="$store.state.appConfig.layout.skin === 'dark' ? 'black' : 'white'"
              />
              <b-row>
                <b-col
                  sm="12"
                  class="mt-1"
                >
                  <b-card-title>Адрес:</b-card-title>
                </b-col>

                <b-col
                  sm="12"
                >
                  <b-row
                    class="match-height"
                  >
                    <b-col
                      v-for="(address, key) in $store.state['app-ecommerce'].cartAddress.address"
                      :key="key"
                      cols="12"
                      md="6"
                    >
                      <b-card
                        :border-variant="$store.state['app-ecommerce'].cartAddress.address[key].selected ? 'primary' : ''"
                        class="cursor-pointer"
                        no-body
                        @click="selectAddress(key)"
                      >
                        <b-card-header class="p-0">
                          <b-card-title />
                          <b-dropdown
                            variant="transparent"
                            right
                            no-caret
                            size="sm"
                          >
                            <template #button-content>
                              <feather-icon
                                icon="MoreVerticalIcon"
                                size="18"
                                class="cursor-pointer"
                              />
                            </template>
                            <b-dropdown-item
                              @click.stop="changeAddress(key)"
                            >
                              <span>Изменить</span>
                            </b-dropdown-item>
                            <b-dropdown-item
                              @click.stop="deleteAddress(key)"
                            >
                              <span>Удалить</span>
                            </b-dropdown-item>
                          </b-dropdown>
                        </b-card-header>
                        <b-card-body>
                          <b-table
                            :items="[address]"
                            :fields="fields"
                            stacked
                            class="address-table"
                          >
                            <template #cell(country)="data">
                              <b-form-input
                                v-if="$store.state['app-ecommerce'].cartAddress.address[key].isEdit"
                                v-model="$store.state['app-ecommerce'].cartAddress.address[key].country"
                                type="text"
                                @click.stop="() => {}"
                              />
                              <span v-else>{{ data.value }}</span>
                            </template>
                            <template #cell(flatt)="data">
                              <b-form-input
                                v-if="$store.state['app-ecommerce'].cartAddress.address[key].isEdit"
                                v-model="$store.state['app-ecommerce'].cartAddress.address[key].flatt"
                                type="text"
                                @click.stop="() => {}"
                              />
                              <span v-else>{{ data.value }}</span>
                            </template>
                            <template #cell(house)="data">
                              <b-form-input
                                v-if="$store.state['app-ecommerce'].cartAddress.address[key].isEdit"
                                v-model="$store.state['app-ecommerce'].cartAddress.address[key].house"
                                type="text"
                                @click.stop="() => {}"
                              />
                              <span v-else>{{ data.value }}</span>
                            </template>
                            <template #cell(postcode)="data">
                              <b-form-input
                                v-if="$store.state['app-ecommerce'].cartAddress.address[key].isEdit"
                                v-model="$store.state['app-ecommerce'].cartAddress.address[key].postcode"
                                type="text"
                                @click.stop="() => {}"
                              />
                              <span v-else>{{ data.value }}</span>
                            </template>
                            <template #cell(street)="data">
                              <b-form-input
                                v-if="$store.state['app-ecommerce'].cartAddress.address[key].isEdit"
                                v-model="$store.state['app-ecommerce'].cartAddress.address[key].street"
                                type="text"
                                @click.stop="() => {}"
                              />
                              <span v-else>{{ data.value }}</span>
                            </template>
                            <template #cell(town_select)="data">
                              <b-form-group
                                v-if="$store.state['app-ecommerce'].cartAddress.address[key].isEdit"
                                label="Город/Регион"
                                label-for="town_select"
                                class="mb-2"
                              >
                                <v-select
                                  id="town_select"
                                  v-model="$store.state['app-ecommerce'].cartAddress.address[key].town_select"
                                  :clearable="false"
                                  :get-option-label="option => option.query"
                                  :options="options.length ? options : (($store.state['app-ecommerce'].cartAddress.address[key].town_select && [$store.state['app-ecommerce'].cartAddress.address[key].town_select]) || [])"
                                  @search="fetchData"
                                  @click.native.stop="() => {}"
                                >
                                  <template v-slot:no-options="{ search, searching }">
                                    <template
                                      v-if="searching"
                                    >
                                      Поиск <em>{{ search }}</em>...
                                    </template>
                                    <em
                                      v-else
                                    >Поиск...</em>
                                  </template>
                                </v-select>
                              </b-form-group>
                              <span v-else>{{ data.value.query }}</span>
                            </template>
                          </b-table>
                          <div
                            v-if="$store.state['app-ecommerce'].cartAddress.address[key].isEdit"
                          >
                            <b-button
                              variant="primary"
                              class="mt-1"
                              :disabled="$store.state['app-ecommerce'].cartAddress.address[key].loading"
                              @click.stop="saveAddress(key)"
                            >
                              <b-spinner
                                v-if="$store.state['app-ecommerce'].cartAddress.address[key].loading"
                                small
                                class="mr-50"
                              />
                              <span>Сохранить</span>
                            </b-button>
                            <b-button
                              variant="outline-danger"
                              class="mt-1 ml-75"
                              @click.stop="cancelAddress(key)"
                            >
                              <span>Отменить</span>
                            </b-button>
                          </div>
                        </b-card-body>
                      </b-card>
                    </b-col>
                    <b-col
                      cols="12"
                      md="6"
                    >
                      <b-card
                        body-class="d-flex justify-content-center align-items-center"
                      >
                        <b-button
                          variant="outline-primary"
                          @click="addAddress"
                        >
                          Добавить адрес
                        </b-button>
                      </b-card>
                    </b-col>
                  </b-row>
                </b-col>

                <b-col sm="12">
                  <hr>
                </b-col>

                <b-col
                  sm="12"
                  class="mt-1"
                >
                  <b-card-title>Доставка:</b-card-title>
                </b-col>

                <b-col
                  sm="12"
                >
                  <b-row class="match-height">
                    <b-col
                      v-for="(delivery, key) in $store.state['app-ecommerce'].cartAddress.delivery"
                      :key="key"
                      md="4"
                    >
                      <b-card
                        :img-src="server + delivery.image"
                        img-top
                        :img-alt="delivery.name"
                        :border-variant="delivery.default === true ? 'primary' : ''"
                        class="cursor-pointer"
                        @click="selectDelivery(delivery)"
                      >
                        <b-card-text>
                          <h5>{{ delivery.name }}</h5>
                        </b-card-text>
                        <b-card-text v-if="delivery.description">
                          <div v-html="delivery.description" />
                        </b-card-text>
                        <b-card-text>
                          <strong v-if="delivery.priceText"><span v-html="delivery.priceText" /></strong>
                        </b-card-text>
                      </b-card>
                    </b-col>
                  </b-row>
                </b-col>

                <!-- Submit Button -->
                <b-col cols="12">
                  <b-button
                    type="submit"
                    variant="primary"
                    :disabled="loading"
                  >
                    <div class="d-flex align-items-center justify-content-center">
                      <b-spinner
                        v-if="loading"
                        small
                        class="mr-50"
                      />
                      <span>Сохранить</span>
                    </div>
                  </b-button>
                </b-col>
              </b-row>
            </b-tab>
            <b-tab
              :active="$store.state['app-ecommerce'].orderData.deliveryType === 'office'"
              class="relative"
              @click="fetchCartAddress('office')"
            >
              <template #title>
                <font-awesome-icon icon="fas fa-building" />
                <span>Получение в офисе</span>
              </template>
              <b-overlay
                :show="loadingAddress"
                no-wrap
                :variant="$store.state.appConfig.layout.skin === 'dark' ? 'black' : 'white'"
              />
              <b-row>
                <b-col
                  sm="12"
                  class="mt-1"
                >
                  <b-card-title>Офис:</b-card-title>
                </b-col>

                <b-col
                  sm="12"
                >
                  <b-row class="match-height">
                    <b-col
                      v-for="(office, key) in $store.state['app-ecommerce'].cartAddress.office"
                      :key="key"
                      md="6"
                    >
                      <b-card
                        :border-variant="$store.state['app-ecommerce'].cartAddress.office[key].selected ? 'primary' : ''"
                        class="cursor-pointer"
                        @click="selectOffice(key)"
                      >
                        <b-card-text>
                          <h5>{{ office.name }}</h5>
                        </b-card-text>
                        <b-card-text>
                          <h4>Адрес:</h4>
                          <div v-html="office.address" />
                        </b-card-text>
                        <b-card-text>
                          <h4>Часы работы:</h4>
                          <div v-html="office.workInfo" />
                        </b-card-text>
                        <div>
                          <div
                            class="map-container2"
                          >
                            <yandex-map
                              v-if="office.map"
                              :coords="office.map"
                              zoom="10"
                              @map-was-initialized="mapLoaded2"
                            >
                              <ymap-marker
                                :coords="office.map"
                                hint-content=""
                                :marker-id="office.id"
                              />
                            </yandex-map>
                          </div>
                        </div>
                        <b-card-text v-if="office.descr">
                          <div v-html="office.descr" />
                        </b-card-text>
                      </b-card>
                    </b-col>
                  </b-row>
                </b-col>

              </b-row>
            </b-tab>
          </b-tabs>
        </b-card-body>
      </b-card>

      <!-- Checkout Options -->
      <e-commerce-checkout-price-details
        label="Перейти к оплате"
        @next-step="checkCart"
        :loading="loadingCheckCart"
      />
    </b-form>
  </validation-observer>
</template>

<script>
import {
  BForm, BTable, BTabs, BTab, BCard, BCardHeader, BCardTitle, BCardText,
  BCardBody, BRow, BCol, BFormGroup, BFormInput,
  BButton, BFormInvalidFeedback, BFormRadioGroup, BFormRadio,
  BSpinner, BOverlay, BDropdown, BDropdownItem, BFormTextarea,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { yandexMap, ymapMarker } from 'vue-yandex-maps'
import { ref } from '@vue/composition-api'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, integer } from '@validations'
import { debounce } from 'lodash'
import formValidation from '@core/comp-functions/forms/form-validation'
import $ from 'jquery'
import ECommerceCheckoutPriceDetails from './ECommerceCheckoutPriceDetails.vue'
import ReadMore from '@/@core/components/read-more/ReadMore.vue'
import store from '@/store'

export default {
  components: {
    // BSV
    BForm,
    BTable,
    BTabs,
    BTab,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    BFormInvalidFeedback,
    BFormRadioGroup,
    BFormRadio,
    BFormTextarea,
    vSelect,
    ReadMore,
    yandexMap,
    ymapMarker,
    BSpinner,
    BOverlay,
    BDropdown,
    BDropdownItem,

    // Form Validation
    ValidationProvider,
    ValidationObserver,

    ECommerceCheckoutPriceDetails,
  },
  props: {
    nextStep: {
      type: Function,
      default: () => {},
    },
  },
  /* eslint-disable */
  data() {
    return {
      isEdit: false,
      fields: [
        { key: "postcode", label: "Индекс", tdClass: 'pl-0 pr-0 no-border'},
        { key: "country", label: "Страна", tdClass: 'pl-0 pr-0'},
        { key: "town_select", label: "Город/Регион", tdClass: 'pl-0 pr-0'},
        { key: "street", label: "Улица", tdClass: 'pl-0 pr-0'},
        { key: "house", label: "Дом", tdClass: 'pl-0 pr-0'},
        { key: "flatt", label: "Квартира/Офис", tdClass: 'pl-0 pr-0'},
      ],
      fields2: [
        { key: "deliveryName", label: "Служба доставки", tdClass: 'pl-0 pr-0 no-border'},
        { key: "name", label: "Пункт выдачи", tdClass: 'pl-0 pr-0'},
        { key: "address", label: "Адрес", tdClass: 'pl-0 pr-0'},
        { key: "description", label: "Описание", tdClass: 'pl-0 pr-0'},
      ],
    };
  },
  setup(props, { root }) {
    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(() => {})
    const mapContainer = ref(null)
    const loading = ref(false)
    const loadingPVZMap = ref(false)
    const deliveryType = ref(null)
    const selectedDelivery = ref(null)

    const markers = ref(null)
    const modalMapShow = ref(false)

    const selectedMarker = ref(null)
    const selectedTarget = ref(null)

    const selectedCity = ref(null)

    const map = ref(null)
    const tick = ref(null)
    const coords = ref(null)
    const scale = ref(null)

    const clusterOptions = {
      clusterName: {
        clusterDisableClickZoom: false,
        clusterOpenBalloonOnClick: true,
        clusterBalloonLayout: [
          '<div style="max-height: 200px; overflow: auto;">',
          '{% for geoObject in properties.geoObjects %}',
          '<div class="form-check"><input data-markerId="{{ geoObject.properties.markerId }}" class="list_item form-check-input" type="radio" name="flexRadioDefault" id="id{{ geoObject.properties.markerId }}"><label class="form-check-label" for="id{{ geoObject.properties.markerId }}">{{ geoObject.properties.balloonContentHeader|raw }}</label></div>',
          '{% endfor %}',
          '</div>',
        ].join(''),
      },
    }

    const selectedCluster = ref(false)
    const clickOnCluster = {
      clusterName: {
        click: () => {
          // selectedCluster.value = true
        }
      }
    }

    const mapLoaded = m => {
      map.value = m
      map.value.controls.remove('geolocationControl')
      map.value.controls.remove('searchControl')
      map.value.controls.remove('trafficControl')
      map.value.controls.remove('typeSelector')
      map.value.controls.remove('rulerControl')
      map.value.controls.remove('fullscreenControl')

      map.value.events.add('actiontickcomplete', debounce(e => {
        if (!selectedMarker.value) {
          markers.value = null
          loadingPVZMap.value = true
          tick.value = e.get('tick')

          const delivery = store.state['app-ecommerce'].cartAddress.delivery.filter(item => item.default === true)[0]

          if (!selectedCity.value) {
            coords.value = map.value.options.get('projection').fromGlobalPixels(tick.value.globalPixelCenter, tick.value.zoom)
          }

          store.dispatch('app-ecommerce/fetchCartPVZ', JSON.stringify({
            delivery: {
              value: delivery && delivery.value,
            },
            mapscore: tick.value.zoom,
            map: coords.value,
            size: [600, 300],
          }))
            .then(response => {
              const { data } = response.data

              markers.value = data.map(item => {
                return {
                  coords: JSON.parse(item.map),
                  address: item.address,
                  deliveryName: item.deliveryName,
                  description: item.description,
                  id: item.id,
                  map: item.map,
                  name: item.name,
                  price: item.price,
                  colorIcon: item.colorIcon,
                }
              })

              $(document).off('change', 'input.list_item').on('change', 'input.list_item', function(e) {
                const markerId = $(this).data().markerid

                selectedMarker.value = markers.value[markerId]
              })

              loadingPVZMap.value = false

              selectedCity.value = null

            })
            .catch(() => loadingPVZMap.value = false)
        }
      }, 500))
    }

    const mapLoaded2 = m => {
      m.controls.remove('geolocationControl')
      m.controls.remove('searchControl')
      m.controls.remove('trafficControl')
      m.controls.remove('typeSelector')
      m.controls.remove('rulerControl')
    }

    const handleClickMap = e => {}

    const handleMarkerClick = (marker, e) => {
      if (selectedTarget.value && selectedMarker.value) {
        selectedTarget.value.options.set('preset', selectedMarker.value.colorIcon)
      }
      e.get('target').options.set('preset', 'islands#violetIcon')
      selectedMarker.value = marker
      selectedTarget.value = e.get('target')
    }

    const handleBalloonOpen = (marker, e) => {
      e.get('target').options.set('preset', 'islands#dotIcon')
      selectedMarker.value = marker
      selectedTarget.value = e.get('target')
    }

    const handleBalloonClose = e => {
      if (selectedTarget.value && selectedMarker.value) {
        selectedTarget.value.options.set('preset', selectedMarker.value.colorIcon)
      }
      selectedMarker.value = null
      selectedTarget.value = null
    }

    const addPVZ = () => {
      if (selectedMarker.value) {
        loadingPVZ.value = true
        store.state['app-ecommerce'].cartAddress.pvz.forEach((addr, k) => {
          store.state['app-ecommerce'].cartAddress.pvz[k].selected = false
        })
        store.commit('app-ecommerce/ADD_CART_PVZ', {
          ...store.state['app-ecommerce'].cartAddress,
          pvz: [
            ...store.state['app-ecommerce'].cartAddress.pvz,
            {
              ...selectedMarker.value,
              selected: true,
            }
          ],
        })

        const delivery = store.state['app-ecommerce'].cartAddress.delivery.filter(item => item.default === true)[0]
        const xparam1 = JSON.stringify({
          ...store.state['app-ecommerce'].cartAddress,
          delivery: {
            value: delivery && delivery.value,
            price: delivery && delivery.price,
          },
          deliveryType: 'PVZ',
        })

        store.dispatch('app-ecommerce/addCartAddress', {
          xparam1,
        })
          .then(() => {
            store.commit('app-ecommerce/UPDATE_CART_ADDRESS', {
              ...store.state['app-ecommerce'].cartAddress,
              pvz: [
                ...store.state['app-ecommerce'].cartAddress.pvz,
              ],
            })
            loadingPVZ.value = false
            selectedMarker.value = null
            fetchCartPriceDetail()
          })
          .catch(() => {
            loadingPVZ.value = false
            selectedMarker.value = null
          })
      }
    }

    const deletePVZ = key => {
      loadingPVZ.value = true
      store.state['app-ecommerce'].cartAddress.pvz.splice(key, 1)

      if (store.state['app-ecommerce'].cartAddress.pvz.length) {
        store.state['app-ecommerce'].cartAddress.pvz[store.state['app-ecommerce'].cartAddress.pvz.length - 1].selected = true
      }

      store.commit('app-ecommerce/UPDATE_CART_ADDRESS', {
        ...store.state['app-ecommerce'].cartAddress,
        pvz: [
          ...store.state['app-ecommerce'].cartAddress.pvz,
        ],
      })

      const delivery = store.state['app-ecommerce'].cartAddress.delivery.filter(item => item.default === true)[0]
      const xparam1 = JSON.stringify({
        ...store.state['app-ecommerce'].cartAddress,
        delivery: {
          value: delivery && delivery.value,
          price: delivery && delivery.price,
        },
        deliveryType: 'PVZ',
      })

      store.dispatch('app-ecommerce/addCartAddress', {
        xparam1,
      })
        .then(() => {
          store.commit('app-ecommerce/UPDATE_CART_ADDRESS', {
            ...store.state['app-ecommerce'].cartAddress,
            pvz: [
              ...store.state['app-ecommerce'].cartAddress.pvz,
            ],
          })
          loadingPVZ.value = false
          fetchCartPriceDetail()
        })
        .catch(() => loadingPVZ.value = false)
    }

    const fetchCartPriceDetail = () => {
      store.dispatch('app-ecommerce/fetchCartPriceDetail')
        .then(response => {
          const { data } = response.data
          store.commit('app-ecommerce/UPDATE_CART_TOTALS', data || [])
        })
    }

    const loadingPVZ = ref(false)
    const loadingAddress = ref(false)

    const fetchCartAddress = xparam1 => {
      deliveryType.value = xparam1 || store.state['app-ecommerce'].orderData.deliveryType || 'PVZ'

      if (xparam1 === 'PVZ') {
        loadingPVZ.value = true
      } else {
        loadingAddress.value = true
      }
      store.dispatch('app-ecommerce/fetchCartAddress', {
        xparam1,
      })
        .then(response => {
          const { data } = response.data
          if (data) {
            store.commit('app-ecommerce/UPDATE_CART_ADDRESS', { ...data[0] })
          }

          if (xparam1 === 'PVZ') {
            loadingPVZ.value = false
            store.state['app-ecommerce'].cartAddress.delivery.forEach((delivery, key) => {
              if (delivery.default) {
                selectedDelivery.value = key
              }
            })
          } else {
            loadingAddress.value = false
          }

          store.dispatch('app-ecommerce/addCartAddress', {
            xparam1: JSON.stringify({
              ...store.state['app-ecommerce'].cartAddress,
              deliveryType: xparam1,
            }),
          })
            .then(() => {
              fetchCartPriceDetail()
            })
        })
        .catch(() => {
          if (xparam1 === 'PVZ') {
            loadingPVZ.value = false
          } else {
            loadingAddress.value = false
          }
        })
    }

    const showAddPVZ = () => {
      markers.value = null
      modalMapShow.value = true
      loadingPVZMap.value = true
      const delivery = store.state['app-ecommerce'].cartAddress.delivery.filter(item => item.default === true)[0]
      store.dispatch('app-ecommerce/fetchCartPVZ', JSON.stringify({
        delivery: {
          value: delivery && delivery.value,
        },
        mapscore: tick.value && tick.value.zoom,
        map: tick.value && map.value.options.get('projection').fromGlobalPixels(tick.value.globalPixelCenter, tick.value.zoom),
        size: [600, 300],
      }))
        .then(response => {
          const { data } = response.data

          markers.value = data.map(item => {
            return {
              coords: JSON.parse(item.map),
              address: item.address,
              deliveryName: item.deliveryName,
              description: item.description,
              id: item.id,
              map: item.map,
              name: item.name,
              price: item.price,
              colorIcon: item.colorIcon,
            }
          })

          $(document).off('change', 'input.list_item').on('change', 'input.list_item', function(e) {
            const markerId = $(this).data().markerid

            selectedMarker.value = markers.value[markerId]
          })

          loadingPVZMap.value = false
        })
        .catch(() => {
          loadingPVZMap.value = false
        })
    }

    const deliveryInput = val => {
      markers.value = null
      loadingPVZMap.value = true
      store.state['app-ecommerce'].cartAddress.delivery.forEach((delivery, index) => {
        store.state['app-ecommerce'].cartAddress.delivery[index].default = false
        if (delivery.value == val) {
          store.state['app-ecommerce'].cartAddress.delivery[index].default = true

        }
      })

      store.commit('app-ecommerce/UPDATE_CART_ADDRESS', {
        ...store.state['app-ecommerce'].cartAddress,
        delivery: [
          ...store.state['app-ecommerce'].cartAddress.delivery,
        ],
      })
      store.dispatch('app-ecommerce/fetchCartPVZ', JSON.stringify({
        delivery: {
          value: val,
        },
        mapscore: tick.value && tick.value.zoom,
        map: tick.value && map.value.options.get('projection').fromGlobalPixels(tick.value.globalPixelCenter, tick.value.zoom),
        size: [600, 300],
      }))
        .then(response => {
          const { data } = response.data

          markers.value = data.map(item => {
            return {
              coords: JSON.parse(item.map),
              address: item.address,
              deliveryName: item.deliveryName,
              description: item.description,
              id: item.id,
              map: item.map,
              name: item.name,
              price: item.price,
              colorIcon: item.colorIcon,
            }
          })

          $(document).off('change', 'input.list_item').on('change', 'input.list_item', function(e) {
            const markerId = $(this).data().markerid

            selectedMarker.value = markers.value[markerId]
          })

          loadingPVZMap.value = false
        })
        .catch(() => {
          loadingPVZMap.value = false
        })
    }

    const selectPVZ = key => {
      if (store.state['app-ecommerce'].cartAddress.pvz[key].selected === true) {
        return
      }

      loadingPVZ.value = true

      store.state['app-ecommerce'].cartAddress.pvz.forEach((addr, k) => {
        store.state['app-ecommerce'].cartAddress.pvz[k].selected = false
      })
      store.state['app-ecommerce'].cartAddress.pvz[key].selected = true

      store.commit('app-ecommerce/UPDATE_CART_ADDRESS', {
        ...store.state['app-ecommerce'].cartAddress,
        pvz: [
          ...store.state['app-ecommerce'].cartAddress.pvz,
        ],
      })

      const delivery = store.state['app-ecommerce'].cartAddress.delivery.filter(item => item.default === true)[0]
      const xparam1 = JSON.stringify({
        ...store.state['app-ecommerce'].cartAddress,
        delivery: {
          value: delivery && delivery.value,
          price: delivery && delivery.price,
        },
        deliveryType: 'PVZ',
      })

      store.dispatch('app-ecommerce/addCartAddress', {
        xparam1,
      })
        .then(() => {
          loadingPVZ.value = false
          fetchCartPriceDetail()
        })
        .catch(() => loadingPVZ.value = false)
    }

    const selectOffice = key => {
      if (store.state['app-ecommerce'].cartAddress.office[key].selected === true) {
        return
      }

      loadingAddress.value = true

      store.state['app-ecommerce'].cartAddress.office.forEach((addr, k) => {
        store.state['app-ecommerce'].cartAddress.office[k].selected = false
      })
      store.state['app-ecommerce'].cartAddress.office[key].selected = true

      store.commit('app-ecommerce/UPDATE_CART_ADDRESS', {
        ...store.state['app-ecommerce'].cartAddress,
        office: [
          ...store.state['app-ecommerce'].cartAddress.office,
        ],
      })

      const delivery = store.state['app-ecommerce'].cartAddress.delivery.filter(item => item.default === true)[0]
      const xparam1 = JSON.stringify({
        ...store.state['app-ecommerce'].cartAddress,
        delivery: {
          value: delivery && delivery.value,
          price: delivery && delivery.price,
        },
        deliveryType: 'office',
      })

      store.dispatch('app-ecommerce/addCartAddress', {
        xparam1,
      })
        .then(() => {
          loadingAddress.value = false
          fetchCartPriceDetail()
        })
        .catch(() => loadingAddress.value = false)
    }

    const onSubmit = () => {
      loading.value = true
      const delivery = store.state['app-ecommerce'].cartAddress.delivery.filter(item => item.default === true)[0]
      const xparam1 = JSON.stringify({
        ...store.state['app-ecommerce'].cartAddress,
        delivery: {
          value: delivery && delivery.value,
          price: delivery && delivery.price,
        },
        deliveryType: deliveryType.value || 'PVZ',
      })

      if (store.state['app-ecommerce'].cartAddress.address && store.state['app-ecommerce'].cartAddress.address.length) {
        store.state['app-ecommerce'].cartAddress.address.forEach((addr, k) => {
          delete store.state['app-ecommerce'].cartAddress.address[k].isEdit
          delete store.state['app-ecommerce'].cartAddress.address[k].loading
        })
      }

      store.dispatch('app-ecommerce/addCartAddress', {
        xparam1,
      })
        .then(() => {
          fetchCartPriceDetail()
          loading.value = false
        })
        .catch(() => {
          loading.value = false
        })
    }

    const loadingCheckCart = ref(false)
    const checkCart = () => {
      loadingCheckCart.value = true
      const delivery = store.state['app-ecommerce'].cartAddress.delivery.filter(item => item.default === true)[0]
      const xparam1 = JSON.stringify({
        ...store.state['app-ecommerce'].cartAddress,
        delivery: {
          value: delivery && delivery.value,
          price: delivery && delivery.price,
        },
        deliveryType: store.state['app-ecommerce'].orderData.deliveryType || 'PVZ',
      })

      if (store.state['app-ecommerce'].cartAddress.address && store.state['app-ecommerce'].cartAddress.address.length) {
        store.state['app-ecommerce'].cartAddress.address.forEach((addr, k) => {
          delete store.state['app-ecommerce'].cartAddress.address[k].isEdit
          delete store.state['app-ecommerce'].cartAddress.address[k].loading
        })
      }

      store.dispatch('app-ecommerce/checkOutSetCheck', {
        xparam1,
      })
        .then(() => {
          props.nextStep()
          loadingCheckCart.value = false
        })
        .catch(err => {
          root.$swal({
            icon: 'warning',
            html: err.response.data.data.msg,
            customClass: {
              confirmButton: 'btn btn-primary',
            },
          })
          loadingCheckCart.value = false
        })
    }

    const selectDelivery = delivery => {
      const xparam1 = JSON.stringify({
        ...store.state['app-ecommerce'].cartAddress,
        delivery: {
          value: delivery.value,
          price: delivery.price,
        },
      })
      store.dispatch('app-ecommerce/addCartAddress', {
        xparam1,
      })
        .then(() => {
          store.state['app-ecommerce'].cartAddress.delivery.forEach(item => {
            // eslint-disable-next-line no-param-reassign
            item.default = false
          })
          // eslint-disable-next-line no-param-reassign
          delivery.default = true

          store.commit('app-ecommerce/UPDATE_ORDER_DATA', {
            address: {
              ...store.state['app-ecommerce'].cartAddress,
              delivery: {
                value: delivery.value,
                price: delivery.price,
              },
            },
          })

          fetchCartPriceDetail()
        })
    }

    const changeAddress = key => {
      store.state['app-ecommerce'].cartAddress.address[key].isEdit = !store.state['app-ecommerce'].cartAddress.address[key].isEdit
      store.commit('app-ecommerce/UPDATE_CART_ADDRESS', {
        ...store.state['app-ecommerce'].cartAddress,
        address: [
          ...store.state['app-ecommerce'].cartAddress.address,
        ],
      })
    }

    const deleteAddress = key => {
      loadingAddress.value = true
      store.state['app-ecommerce'].cartAddress.address.splice(key, 1)

      if (store.state['app-ecommerce'].cartAddress.address.length) {
        store.state['app-ecommerce'].cartAddress.address[store.state['app-ecommerce'].cartAddress.address.length - 1].selected = true
      }

      store.commit('app-ecommerce/UPDATE_CART_ADDRESS', {
        ...store.state['app-ecommerce'].cartAddress,
        address: [
          ...store.state['app-ecommerce'].cartAddress.address,
        ],
      })

      const delivery = store.state['app-ecommerce'].cartAddress.delivery.filter(item => item.default === true)[0]
      const xparam1 = JSON.stringify({
        ...store.state['app-ecommerce'].cartAddress,
        delivery: {
          value: delivery && delivery.value,
          price: delivery && delivery.price,
        },
        deliveryType: deliveryType.value,
      })

      store.dispatch('app-ecommerce/addCartAddress', {
        xparam1,
      })
        .then(() => {
          store.commit('app-ecommerce/UPDATE_CART_ADDRESS', {
            ...store.state['app-ecommerce'].cartAddress,
            address: [
              ...store.state['app-ecommerce'].cartAddress.address,
            ],
          })
          loadingAddress.value = false
          fetchCartPriceDetail()
        })
        .catch(() => loadingAddress.value = false)
    }

    const cancelAddress = key => {
      store.state['app-ecommerce'].cartAddress.address[key].isEdit = false
      store.commit('app-ecommerce/UPDATE_CART_ADDRESS', {
        ...store.state['app-ecommerce'].cartAddress,
        address: [
          ...store.state['app-ecommerce'].cartAddress.address,
        ],
      })
    }

    const selectAddress = key => {
      if (store.state['app-ecommerce'].cartAddress.address[key].selected === true) {
        return
      }

      loadingAddress.value = true

      store.state['app-ecommerce'].cartAddress.address.forEach((addr, k) => {
        store.state['app-ecommerce'].cartAddress.address[k].selected = false
      })
      store.state['app-ecommerce'].cartAddress.address[key].selected = true

      store.commit('app-ecommerce/UPDATE_CART_ADDRESS', {
        ...store.state['app-ecommerce'].cartAddress,
        address: [
          ...store.state['app-ecommerce'].cartAddress.address,
        ],
      })

      const delivery = store.state['app-ecommerce'].cartAddress.delivery.filter(item => item.default === true)[0]
      const xparam1 = JSON.stringify({
        ...store.state['app-ecommerce'].cartAddress,
        delivery: {
          value: delivery && delivery.value,
          price: delivery && delivery.price,
        },
        deliveryType: deliveryType.value,
      })

      store.dispatch('app-ecommerce/addCartAddress', {
        xparam1,
      })
        .then(() => {
          loadingAddress.value = false
          fetchCartPriceDetail()
        })
        .catch(() => loadingAddress.value = false)
    }

    const addAddress = () => {
      store.state['app-ecommerce'].cartAddress.address.forEach((addr, k) => {
        store.state['app-ecommerce'].cartAddress.address[k].isEdit = false
        store.state['app-ecommerce'].cartAddress.address[k].loading = false
        store.state['app-ecommerce'].cartAddress.address[k].selected = false
      })
      store.commit('app-ecommerce/ADD_CART_ADDRESS', {
        ...store.state['app-ecommerce'].cartAddress,
        address: [
          ...store.state['app-ecommerce'].cartAddress.address,
          {
            country: '',
            flatt: '',
            house: '',
            info: '',
            postcode: '',
            street: '',
            town_name: '',
            town_select: null,
            isEdit: true,
            selected: true,
          }
        ],
        deliveryType: deliveryType.value,
      })
    }

    const saveAddress = key => {
      store.state['app-ecommerce'].cartAddress.address[key].loading = true
      const delivery = store.state['app-ecommerce'].cartAddress.delivery.filter(item => item.default === true)[0]
      const xparam1 = JSON.stringify({
        ...store.state['app-ecommerce'].cartAddress,
        delivery: {
          value: delivery && delivery.value,
          price: delivery && delivery.price,
        },
      })

      store.commit('app-ecommerce/UPDATE_CART_ADDRESS', {
        ...store.state['app-ecommerce'].cartAddress,
        address: [
          ...store.state['app-ecommerce'].cartAddress.address,
        ],
      })

      store.dispatch('app-ecommerce/addCartAddress', {
        xparam1,
      })
        .then(() => {
          store.state['app-ecommerce'].cartAddress.address[key].isEdit = false
          store.state['app-ecommerce'].cartAddress.address[key].loading = false

          store.commit('app-ecommerce/UPDATE_CART_ADDRESS', {
            ...store.state['app-ecommerce'].cartAddress,
            address: [
              ...store.state['app-ecommerce'].cartAddress.address,
            ],
          })
          fetchCartPriceDetail()
        })
        .catch(() => {
          store.state['app-ecommerce'].cartAddress.address[key].loading = false
          store.commit('app-ecommerce/UPDATE_CART_ADDRESS', {
            ...store.state['app-ecommerce'].cartAddress,
            address: [
              ...store.state['app-ecommerce'].cartAddress.address,
            ],
          })
        })
    }

    const options = ref([])

    const fetchData = debounce(val => {
      if (val && val.length > 2) {
        store.dispatch('app-ecommerce/fetchData', {
          params: {
            q: val,
          },
        })
          .then(response => {
            const { data } = response.data
            options.value = data
          })
          .catch(() => {
            options.value = []
          })
      }
    }, 500)

    const options2 = ref([])
    const fetchTownCoord = debounce(val => {
      if (val && val.length > 2) {
        store.dispatch('app-ecommerce/fetchTownCoord', {
          params: {
            q: val,
          },
        })
          .then(response => {
            const { data } = response.data
            options2.value = data
          })
          .catch(() => {
            options2.value = []
          })
      }
    }, 500)

    const searchInput = val => {
      store.commit('app-ecommerce/UPDATE_CART_ADDRESS', {
        ...store.state['app-ecommerce'].cartAddress,
        map: [val.lat, val.lng],
      })

      selectedCity.value = true
      scale.value = val.scale
      coords.value = [val.lat, val.lng]
    }

    const server = process.env.VUE_APP_SERVER

    return {
      server,
      mapContainer,
      loading,
      loadingPVZMap,
      loadingAddress,
      loadingPVZ,
      markers,
      modalMapShow,
      showAddPVZ,
      addPVZ,
      deletePVZ,
      selectPVZ,
      selectOffice,
      deliveryInput,
      selectedDelivery,
      selectedMarker,
      mapLoaded,
      mapLoaded2,
      tick,
      coords,
      scale,
      handleClickMap,
      handleMarkerClick,
      handleBalloonClose,
      handleBalloonOpen,
      onSubmit,
      checkCart,
      loadingCheckCart,
      fetchCartAddress,
      selectDelivery,
      changeAddress,
      deleteAddress,
      cancelAddress,
      selectAddress,
      addAddress,
      saveAddress,

      refFormObserver,
      getValidationState,
      resetForm,

      options,
      fetchData,
      options2,
      fetchTownCoord,
      searchInput,

      required,
      integer,
      clusterOptions,
      clickOnCluster,
    }
  },
}
</script>
<style>​
.table.b-table.b-table-stacked > tbody > tr > td::before {
  text-align: left!important;
}
.no-border {
  border: 0!important;
}
.map-container {
  height: calc(100vh - 280px);
  position: relative;
}
.map-container2 {
  height: 160px;
  position: relative;
}
.ymap-container {
  height: 100%;
}

.h-100vh {
  height: 100vh;
}

.relative {
  position: relative;
}

._1E90FF .custom-control-label::before{
  border-color: #1E90FF!important;
}
._1E90FF .custom-control-input:checked ~ .custom-control-label::before{
  border-color: #1E90FF!important;
  background-color: #1E90FF!important;
}
._00008B .custom-control-label::before {
  border-color: #00008B!important;
}
._00008B .custom-control-input:checked ~ .custom-control-label::before{
  border-color: #00008B!important;
  background-color: #00008B!important;
}
._008000 .custom-control-label::before {
  border-color: #008000!important;
}
._008000 .custom-control-input:checked ~ .custom-control-label::before{
  border-color: #008000!important;
  background-color: #008000!important;
}
._808080 .custom-control-label::before {
  border-color: #808080!important;
}
._808080 .custom-control-input:checked ~ .custom-control-label::before{
  border-color: #808080!important;
  background-color: #808080!important;
}
._DC143C .custom-control-label::before {
  border-color: #DC143C!important;
}
._DC143C .custom-control-input:checked ~ .custom-control-label::before{
  border-color: #DC143C!important;
  background-color: #DC143C!important;
}
.color6 .custom-control-label::before {
  border-color: #FFA500!important;
}
.color6 .custom-control-input:checked ~ .custom-control-label::before{
  border-color: #FFA500!important;
  background-color: #FFA500!important;
}
</style>
